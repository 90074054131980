import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getInvoiceTracking } from 'src/services/InvoiceTracking/invoice-tracking.query';
import { InvoiceTracking } from 'src/services/InvoiceTracking/invoice-tracking.types';
import { useSetSellerThemeState } from '../useSellerTheme';
import { defaultTheme } from '../useSellerTheme/atom';
import { FailedSubstatus, OrderStatus, DeliverySubStatus } from 'src/utils/status.enum';

const defaultValue: InvoiceTracking = {
  created_at: new Date(),
  type: 'DELIVERY',
  eta: new Date(),
  last_delivery_type: '',
  carrier_name: '',
  status_name: 'CREATED',
  customer: {
    name: ''
  },
  route: {},
  volumes: [],
  destination_address: {
    latitude: 0,
    longitude: 0
  },
  source_address: {
    latitude: 0,
    longitude: 0,
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: ''
  },
  updated_at: '',
  delivery_events: [
    {
      id: '',
      delivery_id: '',
      status: OrderStatus.CREATED,
      sub_status: null,
      failure_code: '',
      failure_message: '',
      failure_driver_message: '',
      event_at: '',
      created_at: ''
    }
  ]
};

const StatusDoesNotPass = [
  OrderStatus.PENDING,
  OrderStatus.RETURNING,
  OrderStatus.RETURNED,
  OrderStatus.MANUAL_HANDLE,
  OrderStatus.ORDER_FAILED
];

const SubstatusDoesNotPass: DeliverySubStatus[] = [
  FailedSubstatus.COLLECT_FAILED,
  FailedSubstatus.RETURN_FAILED
];

const useInvoiceTracking = (id?: string) => {
  const { pid } = useParams<{ pid: string }>();
  const uniqueId = useMemo(() => id || pid, [id, pid]);
  const { setTheme, setIsLoading } = useSetSellerThemeState();
  const { isLoading, ...result } = useQuery(
    ['invoice-tracking', uniqueId],
    () => getInvoiceTracking(uniqueId),
    {
      enabled: Boolean(uniqueId),
      refetchInterval: 10000,
      retry: false,
      select(data) {
        const newDeliveryEvents = data.delivery_events.filter((event) => {
          if (StatusDoesNotPass.includes(event.status)) {
            return false;
          }
          if (SubstatusDoesNotPass.includes(event.sub_status)) {
            return false;
          }
          return true;
        });

        return data ? { ...data, delivery_events: newDeliveryEvents } : defaultValue;
      },
      onSuccess: (value) => {
        setTheme(value?.theme || defaultTheme);
      },
      onError: () => {
        setTheme(defaultTheme);
      }
    }
  );

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  /* Pegar os volumes de cada InvoiceTracking 
    sendo os volumes export interface Volume {
  id: string;
  items: Item[];
}
  e coloca os itens em um volume só
  */

  const allItems = useMemo(() => {
    if (!result?.data || isLoading) {
      return [];
    }
    return result.data?.volumes.flatMap((volume) => volume.items);
  }, [result.data]);

  const showIntermediaryEventsStatus = useMemo(() => {
    if (!result?.data || isLoading) {
      return false;
    }
    return result.data?.tracking_config?.show_intermediary_status;
  }, [result.data]);

  return {
    ...result,
    isLoading,
    invoice: result.data || defaultValue,
    tracking: uniqueId,
    allItems,
    showIntermediaryEventsStatus
  };
};

export default useInvoiceTracking;
