import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import NotFound from './pages/not-found';
import Search from './pages/search';
import Tracking from './pages/tracking';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/not-found" exact component={NotFound} />
        <Route path="/:pid" exact component={Tracking} />
        <Route path="/" exact component={Search} />
      </Switch>
    </Router>
  );
};

export default Routes;
