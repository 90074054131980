import ButtonLink from 'src/components/atoms/Button/button.link';
import Text from 'src/components/atoms/Text/text.component';

import { Container } from './no-order.styles';

const NoOrder = () => {
  return (
    <Container>
      <Text size="2rem">Nenhum Pedido Encontrado!</Text>
      <ButtonLink variant="contained" color="primary" fullWidth to="/">
        Retornar a Busca
      </ButtonLink>
    </Container>
  );
};

export default NoOrder;
