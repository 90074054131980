import { BaseComponent } from 'src/components/atoms/base';
import BaseText from 'src/components/atoms/Text/text.component';
import styled from 'styled-components';

export const LogoImage = styled.img.attrs({ src: '/logos/abbiamo.svg' })`
  width: 60%;
`;

export const Container = styled.div<BaseComponent>`
  margin: ${(p) => p.m};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0;
`;

export const Text = styled(BaseText)`
  font-size: 2rem;
  margin-left: 1rem;
`;
