import { Container as Logo } from 'src/components/atoms/Logo/logo.styles';
import styled from 'styled-components';

const PageWrapper = styled.div`
  /* max-width: 400px; */
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;

  ${Logo} {
    margin: 10px auto;
  }
`;

export const CenterWrapper = styled(PageWrapper)`
  margin: 0 auto;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  @media screen and (min-width: 500px) {
    min-width: 400px;
  }
`;

export default PageWrapper;
