import React from 'react';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import StatusCircle from 'src/components/atoms/StatusCircle/status-circle.component';
import { InvoiceTracking } from 'src/services/InvoiceTracking/invoice-tracking.types';
import { statusToStatusCircleIndex } from 'src/utils/statusToStatusCircleIndex';

import { defaultStatusList } from './order-status.list';
import { Container, Title } from './order-status.styles';

export interface StatusDefinition {
  icon: any;
  title: string;
  activeColor?: string;
}

interface Props {
  status: InvoiceTracking['status_name'];
  statusList?: StatusDefinition[];
  type?: InvoiceTracking['type'];
}

const OrderStatus: React.FC<Props> = ({
  status,
  statusList = defaultStatusList,
  type = 'DELIVERY'
}) => {
  const selectedIndex = statusToStatusCircleIndex(status, type);
  return (
    <Container>
      {statusList.map(({ icon: Icon, activeColor }, i) => {
        const isFinished = i < selectedIndex;
        const isSelected = i == selectedIndex;
        const isLastSectionSelected = selectedIndex == statusList.length - 1;
        if (isLastSectionSelected)
          return (
            <StatusCircle
              data-cy={`status-circle-${i}`}
              key={i}
              activeColor={activeColor}
              isSelected={isSelected || isFinished}>
              {<CheckOutlinedIcon color="inherit" />}
            </StatusCircle>
          );
        return (
          <StatusCircle
            data-cy={`status-circle-${i}`}
            key={i}
            activeColor={activeColor}
            isSelected={isSelected || isFinished}>
            {isFinished ? <CheckOutlinedIcon color="inherit" /> : <Icon color="inherit" />}
          </StatusCircle>
        );
      })}
      {statusList.map(({ title }, i) => (
        <Title key={i}>{title}</Title>
      ))}
    </Container>
  );
};

export default OrderStatus;
