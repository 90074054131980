import Divider from 'src/components/atoms/Divider/divider.component';
import TrackHeaderSkeleton from 'src/components/molecules/TrackHeader/track-header.skeleton.component';

import OrderStatusSkeleton from '../OrderStatus/order-status.skeleton';
import ProductsSkeleton from '../Products/products.skeleton';
import { Container } from './volume-info.styles';

const VolumeInfoSkeleton: React.FC = () => {
  return (
    <Container>
      <TrackHeaderSkeleton />
      <Divider />
      <OrderStatusSkeleton />
      <Divider />
      <ProductsSkeleton />
    </Container>
  );
};

export default VolumeInfoSkeleton;
