import { BaseComponent } from '../base';
import { Container } from './status-circle.styles';

interface Props extends BaseComponent {
  isSelected?: boolean;
  activeColor?: string;
}

const StatusCircle: React.FC<Props> = ({ children, activeColor, isSelected, ...props }) => {
  return (
    <Container $isSelected={isSelected} $activeColor={activeColor} {...props}>
      {children}
    </Container>
  );
};

export default StatusCircle;
