import { FC } from 'react';
import ExpandableItem from '../ExpandableItem/expandable-item.component';
import ProductTitle from '../ProductTitle/product-title.component';
import {
  Timeline,
  TimelineContent,
  TimelineDescription,
  TimelineItem,
  TimelineTime,
  TimelineTitle,
  TimelineHeader,
  TimelineConnector,
  TimelineIcon
} from '../../ui/timeline';
import { DeliveryStatus } from '@/services/InvoiceTracking/invoice-tracking.types';
import { cn } from 'src/lib/utils';
import { format } from 'date-fns';
import { StatusMapper } from 'src/utils/status.mapper';
import { OrderStatusBadge } from 'src/components/atoms/Badge/badge';

interface EventsProductProps {
  deliveryEvents: DeliveryStatus[];
}

const EventsProduct: FC<EventsProductProps> = ({ deliveryEvents }) => {
  return (
    <ExpandableItem
      title={(isExpanded) => <ProductTitle isExpanded={isExpanded} name={'Histórico'} />}
      last
      cy="delivery-events">
      <Timeline>
        {deliveryEvents.map((event, index) => {
          const isLast = index === deliveryEvents.length - 1;
          const createdAt = format(new Date(event.event_at), 'dd/MM/yy HH:mm');
          const status = StatusMapper.mapStatus(event.status);
          return (
            <TimelineItem key={event.id} className="[&>*]:mb-[-1.4rem]" data-cy={`event-${index}`}>
              <TimelineHeader>
                {!isLast && <TimelineConnector />}
                <TimelineIcon className={cn('bg-gray-500', { 'bg-primary': isLast })} />
              </TimelineHeader>
              <TimelineContent>
                <TimelineTitle className="font-bold text-[1.4rem]">
                  <OrderStatusBadge variant={event.status as any}>{status}</OrderStatusBadge>
                </TimelineTitle>
                <TimelineTime className="font-light">{createdAt}</TimelineTime>
                <TimelineDescription className="text-secondary-foreground">
                  {StatusMapper.mapSubStatus(event.sub_status)}
                </TimelineDescription>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </ExpandableItem>
  );
};

export default EventsProduct;
