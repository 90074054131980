import { IconButton } from '@material-ui/core';
import { useCallback } from 'react';
import useInvoiceTracking from 'src/hooks/useInvoiceTracking/invoice-tracking.hook';
import RefreshIcon from '@material-ui/icons/Refresh';

const Refresh = () => {
  const { refetch, isLoading } = useInvoiceTracking();
  const handleClick = useCallback(() => {
    refetch();
  }, [refetch]);
  return (
    <IconButton
      data-cy="refresh"
      onClick={handleClick}
      disabled={isLoading}
      style={{ position: 'fixed', left: 0, bottom: 0 }}>
      <RefreshIcon />
    </IconButton>
  );
};

export default Refresh;
