export enum OrderStatus {
  CREATED = 'CREATED',
  CANCELED = 'CANCELED',
  START_DELIVERY = 'START_DELIVERY',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  VISIT_LATER = 'VISIT_LATER',
  PENDING = 'PENDING',
  TREATED = 'TREATED',
  DISPATCHED = 'DISPATCHED',
  COLLECTED = 'COLLECTED',
  ORDER_FAILED = 'ORDER_FAILED',
  HANDLING = 'HANDLING',
  MANUAL_HANDLE = 'MANUAL_HANDLE',
  IN_TRANSIT = 'IN_TRANSIT',
  RETURNING = 'RETURNING',
  RETURNED = 'RETURNED'
}

export enum InvoiceStatus {
  CREATED = 1,
  CANCELED = 2,
  START_DELIVERY = 3,
  SUCCESSFUL = 4,
  FAILED = 5,
  VISIT_LATER = 6,
  PENDING = 7,
  TREATED = 8,
  DISPATCHED = 10,
  COLLECTED = 11,
  ORDER_FAILED = 12,
  HANDLING = 13,
  MANUAL_HANDLE = 14,
  IN_TRANSIT = 15,
  RETURNING = 16,
  RETURNED = 17
}

export enum OrderTypes {
  DELIVERY = 'DELIVERY',
  TAKEOUT = 'TAKEOUT',
  RETURN = 'RETURN'
}

export enum OrderPendingSubStatus {
  WAITING_FOR_CARRIER = 'WAITING_FOR_CARRIER',
  WAITING_CARRIER_ACTION = 'WAITING_CARRIER_ACTION',
  WAITING_FOR_DRIVER = 'WAITING_FOR_DRIVER',
  WAITING_TAKEOUT_CONFIRMATION = 'WAITING_TAKEOUT_CONFIRMATION'
}

export enum OrderDispatchedSubStatus {
  ROUTE_PLANNED = 'ROUTE_PLANNED',
  CARRIER_CONFIRMED = 'CARRIER_CONFIRMED',
  DRIVER_CONFIRMED = 'DRIVER_CONFIRMED',
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_REJECTED = 'DRIVER_REJECTED',
  READY_FOR_TAKEOUT = 'READY_FOR_TAKEOUT'
}

export enum OrderFailedSubStatus {
  CARRIER_CANCELED = 'CARRIER_CANCELED',
  CARRIER_REFUSED = 'CARRIER_REFUSED',
  CARRIER_ERROR = 'CARRIER_ERROR',
  CARRIER_TIMEOUT = 'CARRIER_TIMEOUT',
  PACKAGE_NOT_COLLECTED = 'PACKAGE_NOT_COLLECTED',
  SELLER_CANCELED = 'SELLER_CANCELED',
  OUT_OF_COVERAGE = 'OUT_OF_COVERAGE'
}

export enum FailedSubstatus {
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  COLLECT_FAILED = 'COLLECT_FAILED',
  RETURN_FAILED = 'RETURN_FAILED'
}

export enum OrderHandlingSubStatus {
  LOGISTICS_STARTED = 'LOGISTICS_STARTED',
  READY_FOR_TRANSFER = 'READY_FOR_TRANSFER',
  PACKAGE_RECEIVED = 'PACKAGE_RECEIVED',
  IN_TRANSFER = 'IN_TRANSFER',
  TRANSFER_COMPLETED = 'TRANSFER_COMPLETED',
  SCHEDULED_DELIVERY = 'SCHEDULED_DELIVERY',
  REDISPATCHED = 'REDISPATCHED',
  PROCESSED_DELIVERY = 'PROCESSED_DELIVERY'
}

export enum OrderCanceledSubStatus {
  INTERNAL = 'INTERNAL',
  SELLER_CANCELED = 'SELLER_CANCELED',
  ROUTE_CANCELED = 'ROUTE_CANCELED'
}

export enum OrderCreatedSubStatus {
  LAST_ROUTE_CANCELED = 'LAST_ROUTE_CANCELED'
}

export enum OrderSuccessSubStatus {
  DELIVERED = 'DELIVERED',
  WITHDRAWN = 'WITHDRAWN'
}

export enum OrderInTransitSubStatus {
  COLLECTING = 'COLLECTING'
}

export enum TakeoutSubStatus {
  WAITING_TAKEOUT_CONFIRMATION = 'WAITING_TAKEOUT_CONFIRMATION',
  READY_FOR_TAKEOUT = 'READY_FOR_TAKEOUT'
}

export type DeliverySubStatus =
  | OrderPendingSubStatus
  | OrderDispatchedSubStatus
  | OrderSuccessSubStatus
  | OrderCanceledSubStatus
  | OrderFailedSubStatus
  | OrderHandlingSubStatus
  | OrderInTransitSubStatus
  | FailedSubstatus
  | TakeoutSubStatus
  | OrderCreatedSubStatus;
