import styled from 'styled-components';
import useSellerTheme from 'src/hooks/useSellerTheme';

export const Container = styled.div.attrs((props) => {
  const { isLoading } = useSellerTheme();

  return {
    ...props,
    $boxShadowColor: isLoading ? props.theme.colors.loading : props.theme.colors.main
  };
})`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 10px;
  padding-top: 15px;

  position: sticky;
  top: 0;
  z-index: 10;

  background-color: ${(props) => props.theme.colors.bg};
  border-top: solid 3px ${(p) => p.$boxShadowColor};
  border-radius: ${(p) => p.theme.borderRadius};
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  align-self: center;
  margin: 0;
  color: ${(p) => p.theme.colors.bgContrast};
`;
