import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CloseOulinedIcon from '@material-ui/icons/CloseOutlined';
import { PickedUpIcon, PickupIcon } from '../../atoms/Icons/icons';
import { StatusDefinition } from './order-status.component';

export const failStatusList: StatusDefinition[] = [
  {
    icon: AssignmentOutlinedIcon,
    title: 'Criado'
  },
  {
    icon: DashboardOutlinedIcon,
    title: 'Despachado'
  },
  {
    icon: LocalShippingOutlinedIcon,
    title: 'Em Rota'
  },
  {
    icon: CloseOulinedIcon,
    title: 'Falha',
    activeColor: '#FF7979'
  }
];

export const defaultStatusList: StatusDefinition[] = [
  {
    icon: AssignmentOutlinedIcon,
    title: 'Criado'
  },
  {
    icon: DashboardOutlinedIcon,
    title: 'Despachado'
  },
  {
    icon: LocalShippingOutlinedIcon,
    title: 'Em Rota'
  },
  {
    icon: FlagOutlinedIcon,
    title: 'Entregue'
  }
];

export const takeoutStatusList: StatusDefinition[] = [
  {
    icon: AssignmentOutlinedIcon,
    title: 'Recebido'
  },
  {
    icon: DashboardOutlinedIcon,
    title: 'Separação'
  },
  {
    icon: PickupIcon,
    title: 'Pronto para retirada'
  },
  {
    icon: PickedUpIcon,
    title: 'Retirado'
  }
];

export const returnStatusList: StatusDefinition[] = [
  {
    icon: AssignmentOutlinedIcon,
    title: 'Criado'
  },
  {
    icon: DashboardOutlinedIcon,
    title: 'Confirmado'
  },
  {
    icon: LocalShippingOutlinedIcon,
    title: 'Em Rota de Coleta'
  },
  {
    icon: FlagOutlinedIcon,
    title: 'Coletado'
  }
];
