import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

const TextSkeleton = styled(Skeleton).attrs({ variant: 'rect' })<{
  w?: string;
  m?: string;
}>`
  &.MuiSkeleton-root {
    display: block;
    margin: ${(p) => p.m || '0px'};
    border-radius: ${(p) => p.theme.borderRadius};
    height: 30px;
    width: ${(p) => p.w || '50px'};
  }
`;

export default TextSkeleton;
