import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga4';
import ThemeDeps from './theme-deps';
import { RecoilRoot } from 'recoil';
import Headers from './components/organisms/Headers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnMount: false
    }
  }
});

const Deps: React.FC = ({ children }) => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID || ' ');
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ThemeDeps>
          {children}
          <Headers />
        </ThemeDeps>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default Deps;
