import VolumeInfo from 'src/components/molecules/VolumeInfo/volume-info.component';
import VolumeInfoSkeleton from 'src/components/molecules/VolumeInfo/volume-info.skeleton.component';
import RenderManager from 'src/components/shared/RenderManager/render-manager.component';
import useInvoiceTracking from 'src/hooks/useInvoiceTracking/invoice-tracking.hook';

import { Container } from './tracking.styles';

const Tracking: React.FC = () => {
  const { invoice, isLoading, allItems, showIntermediaryEventsStatus } = useInvoiceTracking();
  const isTrackingAvailable = invoice?.status_name !== 'MANUAL_HANDLE';
  return (
    <Container>
      <RenderManager loading={isLoading} loadingComponent={<VolumeInfoSkeleton />}>
        <VolumeInfo
          status={invoice.status_name}
          items={allItems}
          isTrackingAvailable={isTrackingAvailable}
          invoiceType={invoice.type}
          deliveryEvents={invoice.delivery_events}
          showIntermediaryEventsStatus={showIntermediaryEventsStatus}
        />
      </RenderManager>
    </Container>
  );
};

export default Tracking;
