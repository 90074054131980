import { BaseComponent } from 'src/components/atoms/base';

import { Container, LogoImage } from './logo.styles';

type Props = BaseComponent;

const Logo: React.FC<Props> = ({ ...props }) => {
  return (
    <Container {...props}>
      <LogoImage />
    </Container>
  );
};

export default Logo;
