import ReactDOM from 'react-dom';

import Deps from './deps';
import Routes from './routes';
import './theme/globalUI.css';

ReactDOM.render(
  <Deps>
    <Routes />
  </Deps>,
  document.querySelector('#root')
);
