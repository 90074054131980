import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';

const badgeVariants = cva(
  'inline-flex flex-row gap-x-1 items-center rounded-md border px-2 py-0.5 text-[1.3rem] transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        disabled: 'border-transparent bg-primary/20 text-primary/80',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-0 hover:bg-accent hover:text-accent-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  asChild?: boolean;
  active?: boolean;
}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

const orderStatusBadgeVariants = cva(
  'flex flex-row gap-x-1 text-[1.3rem] leading-0 py-0.5 px-2 rounded-md whitespace-nowrap font-medium font-roboto w-fit items-center border uppercase',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-transparent hover:bg-accent hover:text-accent-foreground',
        CREATED:
          'bg-[theme(colors.yellow.400/.1)] text-[theme(colors.yellow.500)] border-[theme(colors.yellow.300)]',
        DISPATCHED:
          'bg-[theme(colors.teal.400/.1)] text-[theme(colors.teal.500)] border-[theme(colors.teal.300)]',
        PENDING:
          'bg-[theme(colors.pink.400/.1)] text-[theme(colors.pink.500)] border-[theme(colors.pink.300)]',
        COLLECTED:
          'bg-[theme(colors.violet.400/.1)] text-[theme(colors.violet.500)] border-[theme(colors.violet.300)]',
        START_DELIVERY:
          'bg-[theme(colors.cyan.400/.1)] text-[theme(colors.cyan.500)] border-[theme(colors.cyan.300)]',
        SUCCESSFUL:
          'bg-[theme(colors.green.400/.1)] text-[theme(colors.green.500)] border-[theme(colors.green.300)]',
        FAILED:
          'bg-[theme(colors.rose.400/.1)] text-[theme(colors.rose.500)] border-[theme(colors.rose.300)]',
        IN_TRANSIT:
          'bg-[theme(colors.sky.400/.1)] text-[theme(colors.sky.500)] border-[theme(colors.sky.300)]',
        RETURNING:
          'bg-[theme(colors.orange.400/.1)] text-[theme(colors.orange.500)] border-[theme(colors.orange.300)]',
        RETURNED:
          'bg-[theme(colors.indigo.400/.1)] text-[theme(colors.indigo.500)] border-[theme(colors.indigo.300)]',
        MANUAL_HANDLE:
          'bg-[theme(colors.neutral.400/.1)] text-[theme(colors.neutral.500)] border-[theme(colors.neutral.300)]',
        HANDLING:
          'bg-[theme(colors.fuchsia.400/.1)] text-[theme(colors.fuchsia.500)] border-[theme(colors.fuchsia.300)]',
        ORDER_FAILED:
          'bg-[theme(colors.red.400/.1)] text-[theme(colors.red.500)] border-[theme(colors.red.300)]'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface OrderStatusBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof orderStatusBadgeVariants> {
  asChild?: boolean;
}

function OrderStatusBadge({ className, variant, ...props }: OrderStatusBadgeProps) {
  return <div className={cn(orderStatusBadgeVariants({ variant }), className)} {...props} />;
}

const reportStatusBadgeVariants = cva(
  'flex flex-row gap-x-1 text-[1.3rem] leading-0 py-0.5 px-2 rounded-md whitespace-nowrap font-medium font-roboto w-fit items-center border',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-transparent hover:bg-accent hover:text-accent-foreground',
        QUEUED:
          // QUEUED is still not working so it is implicitly the same as PROCESSING. When it works we can change colors
          // 'bg-[theme(colors.violet.400/.1)] text-[theme(colors.violet.500)] border-[theme(colors.violet.300)]',
          'bg-[theme(colors.cyan.400/.1)] text-[theme(colors.cyan.500)] border-[theme(colors.cyan.300)]',
        PROCESSING:
          'bg-[theme(colors.cyan.400/.1)] text-[theme(colors.cyan.500)] border-[theme(colors.cyan.300)]',
        DONE: 'bg-[theme(colors.green.400/.1)] text-[theme(colors.green.500)] border-[theme(colors.green.300)]',
        FAILED:
          'bg-[theme(colors.rose.400/.1)] text-[theme(colors.rose.500)] border-[theme(colors.rose.300)]',
        EXPIRED:
          'bg-[theme(colors.neutral.400/.1)] text-[theme(colors.neutral.500)] border-[theme(colors.neutral.300)]'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface ReportStatusBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof reportStatusBadgeVariants> {
  asChild?: boolean;
}

function ReportStatusBadge({ className, variant, ...props }: ReportStatusBadgeProps) {
  return <div className={cn(reportStatusBadgeVariants({ variant }), className)} {...props} />;
}

const webhookEventTypeBadgeVariants = cva(
  'flex flex-row gap-x-1 text-[1.3rem] leading-0 py-0.5 px-2 rounded-md whitespace-nowrap font-medium font-roboto w-fit items-center border',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-transparent hover:bg-accent hover:text-accent-foreground',
        ORDER_RECEIVER_UPDATE:
          'bg-[theme(colors.violet.400/.1)] text-[theme(colors.violet.500)] border-[theme(colors.violet.300)]',
        ORDER_STATUS_CHANGE:
          'bg-[theme(colors.cyan.400/.1)] text-[theme(colors.cyan.500)] border-[theme(colors.cyan.300)]',
        ROUTE_STATUS_CHANGE:
          'bg-[theme(colors.green.400/.1)] text-[theme(colors.green.500)] border-[theme(colors.green.300)]',
        ROUTE_CREATION_FAILURE:
          'bg-[theme(colors.rose.400/.1)] text-[theme(colors.rose.500)] border-[theme(colors.rose.300)]'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface WebhookEventTypeBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof webhookEventTypeBadgeVariants> {
  asChild?: boolean;
}

function WebhookEventTypeBadge({ className, variant, ...props }: WebhookEventTypeBadgeProps) {
  return <div className={cn(webhookEventTypeBadgeVariants({ variant }), className)} {...props} />;
}

const routeStatusBadgeVariants = cva(
  'flex flex-row gap-x-1 text-[1.3rem] leading-0 py-0.5 px-2 rounded-md whitespace-nowrap font-medium font-roboto w-fit items-center border uppercase',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-transparent hover:bg-accent hover:text-accent-foreground',
        CREATED:
          'bg-[theme(colors.yellow.400/.1)] text-[theme(colors.yellow.500)] border-[theme(colors.yellow.300)]',
        START_DELIVERY:
          'bg-[theme(colors.cyan.400/.1)] text-[theme(colors.cyan.500)] border-[theme(colors.cyan.300)]',
        FINISHED:
          'bg-[theme(colors.green.400/.1)] text-[theme(colors.green.500)] border-[theme(colors.green.300)]',
        ALL_WAYPOINTS_FINISHED:
          'bg-[theme(colors.sky.400/.1)] text-[theme(colors.sky.500)] border-[theme(colors.sky.300)]',
        CANCELED:
          'bg-[theme(colors.neutral.400/.1)] text-[theme(colors.neutral.500)] border-[theme(colors.neutral.300)]'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface RouteStatusBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof routeStatusBadgeVariants> {
  asChild?: boolean;
}

function RouteStatusBadge({ className, variant, ...props }: RouteStatusBadgeProps) {
  return <div className={cn(routeStatusBadgeVariants({ variant }), className)} {...props} />;
}

const carrierIntegrationOperationTypeBadgeVariants = cva(
  'flex flex-row gap-x-1 text-[1.3rem] leading-0 py-0.5 px-2 rounded-md whitespace-nowrap font-medium font-roboto w-fit items-center border',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-transparent hover:bg-accent hover:text-accent-foreground',
        DELIVERY:
          'bg-[theme(colors.violet.400/.1)] text-[theme(colors.violet.500)] border-[theme(colors.violet.300)]',
        RETURN:
          'bg-[theme(colors.cyan.400/.1)] text-[theme(colors.cyan.500)] border-[theme(colors.cyan.300)]'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface CarrierIntegrationOperationTypeBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof carrierIntegrationOperationTypeBadgeVariants> {
  asChild?: boolean;
}

function CarrierIntegrationOperationTypeBadge({
  className,
  variant,
  ...props
}: CarrierIntegrationOperationTypeBadgeProps) {
  return (
    <div
      className={cn(carrierIntegrationOperationTypeBadgeVariants({ variant }), className)}
      {...props}
    />
  );
}

const reportTypeBadgeVariants = cva(
  'flex flex-row gap-x-1 text-[1.3rem] leading-0 py-0.5 px-2 rounded-md whitespace-nowrap font-medium font-roboto w-fit items-center border',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        dashed: 'border-dashed text-muted-foreground',
        ghost: 'border-transparent hover:bg-accent hover:text-accent-foreground',
        ORDERS:
          'bg-[theme(colors.blue.400/.1)] text-[theme(colors.blue.500)] border-[theme(colors.blue.300)]',
        EVENTS:
          'bg-[theme(colors.orange.400/.1)] text-[theme(colors.orange.500)] border-[theme(colors.orange.300)]'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface ReportTypeBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof reportTypeBadgeVariants> {
  asChild?: boolean;
}

function ReportTypeBadge({ className, variant, ...props }: ReportTypeBadgeProps) {
  return <div className={cn(reportTypeBadgeVariants({ variant }), className)} {...props} />;
}

const ActiveBadge = React.forwardRef<React.ElementRef<'span'>, BadgeProps>(
  ({ className, active = true, ...props }) => {
    return (
      <span
        className={cn(
          'h-3 w-3 rounded-full shrink-0',
          active
            ? 'bg-[theme(colors.emerald.500)] ring-4 ring-[theme(colors.emerald.500/.3)]'
            : 'bg-[theme(colors.red.500)] ring-4 ring-[theme(colors.red.500/.3)]',
          className
        )}
        {...props}
      />
    );
  }
);
ActiveBadge.displayName = 'ActiveBadge';

export {
  Badge,
  badgeVariants,
  ActiveBadge,
  OrderStatusBadge,
  orderStatusBadgeVariants,
  ReportStatusBadge,
  reportStatusBadgeVariants,
  WebhookEventTypeBadge,
  webhookEventTypeBadgeVariants,
  RouteStatusBadge,
  routeStatusBadgeVariants,
  CarrierIntegrationOperationTypeBadge,
  carrierIntegrationOperationTypeBadgeVariants,
  ReportTypeBadge,
  reportTypeBadgeVariants
};
