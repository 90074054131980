import LinesEllipsis from 'react-lines-ellipsis';

interface Props {
  name: string;
  isExpanded: boolean;
}

const ProductTitle: React.FC<Props> = ({ name, isExpanded = false }) => {
  return (
    <LinesEllipsis text={name} maxLine={isExpanded ? 3 : 1} ellipsis="..." basedOn="letters" />
  );
};

export default ProductTitle;
