import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/atoms/Button/button.component';
import Input from 'src/components/atoms/Input/input.component';
import Text from 'src/components/atoms/Text/text.component';
import useGetInvoiceTracking from 'src/hooks/useGetInvoiceTracking/get-invoice-tracking.hook';

import { Container } from './search-section.styles';

const SearchSection = () => {
  const [tracking, setTracking] = useState('');

  const handleTrackingChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    useCallback(
      (e) => {
        setTracking(e.target.value);
      },
      [setTracking]
    );

  const history = useHistory();
  const { mutateAsync: getInvoiceTracking, isLoading } = useGetInvoiceTracking();
  const [open, setOpen] = useState(false);

  const redirectToTracking = useCallback(async () => {
    try {
      const invoice = await getInvoiceTracking(tracking);
      if (invoice) {
        history.push(`/${tracking}`);
      } else {
        setOpen(true);
      }
    } catch {
      setOpen(true);
    }
  }, [history, tracking]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <Text size="2rem">Digite o seu Numero de Rastreio Abaixo</Text>
        <Input
          data-cy="tracking-input"
          onChange={handleTrackingChange}
          variant="outlined"
          label="N° Rastreio"
          placeholder="Ex: lzA_I5G"
        />
        <Button
          data-cy="search-button"
          disabled={tracking === '' || isLoading}
          type="button"
          variant="contained"
          color="primary"
          size="medium"
          onClick={redirectToTracking}>
          Buscar
        </Button>
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Pedido Não Encontrado
        </Alert>
      </Snackbar>
    </>
  );
};

export default SearchSection;
