import Logo from 'src/components/atoms/Logo/logo.component';
import NoOrder from 'src/components/organisms/NoOrder/no-order.component';

import { CenterWrapper } from './wrappers';

const NotFound = () => {
  return (
    <CenterWrapper>
      <Logo />
      <NoOrder />
    </CenterWrapper>
  );
};

export default NotFound;
