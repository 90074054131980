import { createTheme as createMuiTheme } from '@material-ui/core';
import { SellerTheme } from '@abbiamo/seller-theme-utils';
import { DefaultTheme } from 'styled-components';

export const getTheme = (theme: SellerTheme): DefaultTheme => ({
  borderRadius: '5px',
  colors: {
    main: theme.primary.value,
    mainContrast: theme.primary.safeColorInsideContainer,
    mainContrastAgainstBg: theme.primary.safeColorAgainstBg,
    safeContainerColor: theme.primary.safeContainerColor,
    bg: 'white',
    bgContrast: '#464646',
    loading: '#c9c9c9',
    gray: {
      500: '#E2E2E2'
    }
  },
  fontSizes: {
    xl: '2.5rem',
    lg: '2rem',
    md: '1.8rem',
    sm: '1.6rem',
    xs: '1.4rem'
  }
});

export const getMuiTheme = (theme: SellerTheme) =>
  createMuiTheme({
    palette: {
      primary: { main: theme.primary.value, contrastText: theme.primary.safeColorInsideContainer }
    },
    typography: {
      fontSize: 25
    }
  });

export type ITheme = DefaultTheme;
