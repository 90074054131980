const capitalize = (word: string) => {
  try {
    const [first_letter, ...rest] = word.split('');
    const lowerCaseRest = (rest || []).map((restWord) => restWord.toLowerCase());
    return `${first_letter.toUpperCase()}${lowerCaseRest.join('')}`;
  } catch {
    return word;
  }
};

export default capitalize;
