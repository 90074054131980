/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';

interface Props {
  loading: boolean;
  loadingComponent: React.ReactNode;
}

const RenderManager: React.FC<Props> = ({ loading, loadingComponent, children }) => {
  const isLoading = useMemo(() => loading, [loading]);
  const canShow = useMemo(() => !isLoading, [isLoading]);
  return <>{isLoading ? loadingComponent : canShow ? children : null}</>;
};

export default RenderManager;
