import styled from 'styled-components';

export const Image = styled.img.attrs({ src: '/imgs/powered_by.svg' })``;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 10px 0;
  margin: 0 auto;
  height: 40px;
`;
