import { atom } from 'recoil';
import { TrackingTheme } from './use-seller-theme.types';
import { getProjectThemeWithContrasts } from '@abbiamo/seller-theme-utils';
import { Theme } from 'src/services/InvoiceTracking/invoice-tracking.types';

export const defaultTheme: Theme = {
  carrier_name: 'Abbiamo',
  logo_bg: '6ed796',
  logo: 'imgs/loading.ico',
  primary: '#6ed796',
  favicon: 'https://abbiamo-public.s3.amazonaws.com/seller-favicons/favicon.ico'
};

const defaultSellerTheme: TrackingTheme = getProjectThemeWithContrasts(defaultTheme);

export interface ISellerThemeAtom {
  theme: TrackingTheme;
  isLoading: boolean;
}

const SellerThemeAtom = atom<ISellerThemeAtom>({
  key: 'seller-theme',
  default: { theme: defaultSellerTheme, isLoading: false }
});

export default SellerThemeAtom;
