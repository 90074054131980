import { useCallback, useMemo, useState } from 'react';
import {
  Container,
  Information,
  ProductImage,
  ProductInfoLine,
  ProductPrice
} from './product-information.styles';

interface Props {
  price: string;
  thumbnail_url: string;
  quantity: number;
}

const ProductInformation: React.FC<Props> = ({ price, thumbnail_url, quantity }) => {
  const [imgHasFailedToLoad, setImgFail] = useState(false);

  const handleOnError = useCallback(() => {
    setImgFail(true);
  }, [setImgFail]);

  const imgUrl = useMemo(() => {
    if (imgHasFailedToLoad) {
      return '/imgs/empty-item.png';
    }
    return thumbnail_url || '/imgs/empty-item.png';
  }, [imgHasFailedToLoad, thumbnail_url]);

  return (
    <Container data-cy="item-container">
      <ProductImage src={imgUrl} onError={handleOnError} />
      <Information>
        <ProductInfoLine>
          Valor:
          <ProductPrice>{price}</ProductPrice>
        </ProductInfoLine>
        <ProductInfoLine>
          Quantidade: {quantity} {quantity === 1 ? 'Unidade' : 'Unidades'}
        </ProductInfoLine>
      </Information>
    </Container>
  );
};

export default ProductInformation;
