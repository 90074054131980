import styled from 'styled-components';

import { BaseComponent } from '../base';
import { size } from './status-circle.variables';

export const Container = styled.div<
  BaseComponent & { $isSelected: boolean; $activeColor?: string }
>`
  background-color: ${(p) => p.theme.colors.bg};
  border: 2px solid
    ${(p) =>
      p.$isSelected
        ? p.$activeColor || p.theme.colors.safeContainerColor
        : p.theme.colors.gray[500]};
  color: ${(p) =>
    p.$isSelected
      ? p.$activeColor || p.theme.colors.safeContainerColor
      : p.theme.colors.bgContrast};
  font-size: 2.5rem;
  width: ${size}px;
  height: ${size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  align-self: center;
  justify-self: center;
`;
