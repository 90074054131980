import Text from 'src/components/atoms/Text/text.component';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  padding: 10px 20px;
  width: 100%;
`;

export const Title = styled(Text)`
  font-size: 2rem;
  color: ${(p) => p.theme.colors.bgContrast};
`;

export const VolumeNumber = styled.b`
  color: ${(p) => p.theme.colors.main};
`;

export const Information = styled.div`
  color: ${(p) => p.theme.colors.bgContrast};
  font-size: 1.8rem;
`;
