import { Link } from 'react-router-dom';

import Button from './button.component';

type Props = Parameters<typeof Button>[0] & { to: string };

const ButtonLink: React.FC<Props> = ({ to, ...buttonProps }) => {
  return <Button {...buttonProps} component={(props) => <Link {...props} to={to} />}></Button>;
};

export default ButtonLink;
