import Text from 'src/components/atoms/Text/text.component';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const ProductImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: ${(p) => p.theme.borderRadius};
  border: 1px solid ${(p) => p.theme.colors.gray[500]};
  margin-right: 10px;
`;

export const ProductInfoLine = styled(Text)`
  display: inline-block;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: Roboto;
  color: ${(p) => p.theme.colors.bgContrast};
  margin: 2px 0;
`;

export const ProductStatus = styled.span`
  display: inline-block;
  color: ${(p) => p.theme.colors.main};
  margin-left: 5px;
`;

export const ProductPrice = styled.span`
  display: inline-block;
  color: ${(p) => p.theme.colors.mainContrastAgainstBg};
  margin-left: 5px;
`;
