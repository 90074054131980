import styled from 'styled-components';

export const Container = styled.div<{ $bg: string }>`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 10px 40px;
  height: fit-content;
  z-index: 10;
  border-radius: 5px;
  background-color: ${(p) => p.$bg};
`;

export const Logo = styled.img`
  max-width: 200px;
  max-height: 5.5rem;
  margin: 0 auto;
  display: block;
`;
