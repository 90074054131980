import { BaseComponent } from '../base';
import { TextContainer } from './text.styles';

interface Props extends BaseComponent {
  children: React.ReactNode;
  size?: string | number;
}

const Text: React.FC<Props> = ({ children, size, ...props }) => {
  return (
    <TextContainer style={{ fontSize: size || '1.8rem' }} {...props}>
      {children}
    </TextContainer>
  );
};

export default Text;
