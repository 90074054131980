import ExpandableItem from 'src/components/molecules/ExpandableItem/expandable-item.component';
import ProductInformation from 'src/components/molecules/ProductInformation/product-information.component';
import ProductTitle from 'src/components/molecules/ProductTitle/product-title.component';
import { Volume } from 'src/services/InvoiceTracking/invoice-tracking.types';
import { numberToBrl } from 'src/utils/numberToBrl';
import { Container } from './products.styles';

interface Props {
  items: Volume['items'];
}

const Products: React.FC<Props> = ({ items = [] }) => {
  return (
    <Container data-cy="items-container">
      <ExpandableItem
        title={(isExpanded) => <ProductTitle isExpanded={isExpanded} name={'Produtos'} />}
        last
        cy="items-products">
        {items.map((i, index) => (
          <ExpandableItem
            title={(isExpandedd) => <ProductTitle isExpanded={isExpandedd} name={i.name} />}
            last={index === items.length - 1}
            sizeTitle="sm"
            key={index}
            cy={`item-product-${index}`}>
            <ProductInformation
              thumbnail_url={i.thumb_url}
              price={numberToBrl(i.amount)}
              quantity={i.quantity}
            />
          </ExpandableItem>
        ))}
      </ExpandableItem>
    </Container>
  );
};

export default Products;
