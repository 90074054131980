import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { Theme } from 'src/services/InvoiceTracking/invoice-tracking.types';
import SellerThemeAtom from './atom';
import { getProjectThemeWithContrasts } from '@abbiamo/seller-theme-utils';

export const useSetSellerThemeState = () => {
  const setter = useSetRecoilState(SellerThemeAtom);
  const set = useCallback(
    (theme: Theme) => {
      setter((p) => ({ theme: getProjectThemeWithContrasts(theme), isLoading: p.isLoading }));
    },
    [setter]
  );
  const setIsLoading = useCallback(
    (newIsLoading) => {
      setter((p) => ({ ...p, isLoading: newIsLoading }));
    },
    [setter]
  );
  return { setTheme: set, setIsLoading };
};
