import SkeletonExpandableItem from '../ExpandableItem/expandable-item.skeleton';
import { Container } from './products.styles';

const ProductsSkeleton = () => {
  return (
    <Container>
      {[...new Array(3)].map((_, index) => (
        <SkeletonExpandableItem key={index} />
      ))}
    </Container>
  );
};

export default ProductsSkeleton;
