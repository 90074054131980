import {
  DeliveryStatus,
  InvoiceTracking,
  Item
} from 'src/services/InvoiceTracking/invoice-tracking.types';

import { Container } from './volume-info.styles';
import Divider from 'src/components/atoms/Divider/divider.component';
import OrderStatus from 'src/components/molecules/OrderStatus/order-status.component';
import {
  defaultStatusList,
  failStatusList,
  returnStatusList,
  takeoutStatusList
} from 'src/components/molecules/OrderStatus/order-status.list';
import Products from '../Products/products.component';
import useInvoiceUtils from 'src/hooks/useInvoiceUtils';
import { useMemo } from 'react';
import EventsProduct from '../EventsProduct/events-product.component';

interface Props {
  items: Item[];
  status: InvoiceTracking['status_name'];
  isTrackingAvailable: boolean;
  invoiceType: InvoiceTracking['type'];
  deliveryEvents: DeliveryStatus[];
  showIntermediaryEventsStatus: boolean;
}

const VolumeInfo: React.FC<Props> = ({
  status,
  items,
  isTrackingAvailable,
  invoiceType,
  deliveryEvents,
  showIntermediaryEventsStatus
}) => {
  const { isCanceled, hasFailed } = useInvoiceUtils();
  const happyPathStatusList = useMemo(() => {
    switch (invoiceType) {
      case 'TAKEOUT':
        return takeoutStatusList;
      case 'DELIVERY':
        return defaultStatusList;
      case 'RETURN':
        return returnStatusList;
      default:
        return defaultStatusList;
    }
  }, [invoiceType]);
  const statusList = hasFailed ? failStatusList : happyPathStatusList;
  return (
    <Container>
      {isTrackingAvailable && !isCanceled && (
        <>
          <OrderStatus status={status} statusList={statusList} type={invoiceType} />
          <Divider />
        </>
      )}
      {showIntermediaryEventsStatus && (
        <>
          <EventsProduct deliveryEvents={deliveryEvents} />
          <Divider />
        </>
      )}

      <Products items={items} />
    </Container>
  );
};

export default VolumeInfo;
