import { DeliverySubStatus, OrderStatus } from './status.enum';
const subStatusMap: { [key in DeliverySubStatus]: string } = {
  WAITING_FOR_CARRIER: 'Aguardando Transportadora',
  WAITING_CARRIER_ACTION: 'Aguardando Ação com a Transportadora',
  ROUTE_PLANNED: 'Rota Planejada',
  CARRIER_CONFIRMED: 'Transportadora Confirmou',
  INTERNAL: 'Interno',
  CARRIER_REFUSED: 'Transportadora Recusou',
  PACKAGE_RECEIVED: 'Objeto Recebido',
  PACKAGE_NOT_COLLECTED: 'Pacote Não Coletado',
  LOGISTICS_STARTED: 'Logística Iniciada',
  READY_FOR_TRANSFER: 'Preparado para transferência',
  IN_TRANSFER: 'Em Transferência',
  TRANSFER_COMPLETED: 'Transferência Completa',
  REDISPATCHED: 'Redespachado',
  LAST_ROUTE_CANCELED: 'Rota Anterior Cancelada',
  PROCESSED_DELIVERY: 'Processado para entrega',
  ROUTE_CANCELED: 'Rota Cancelada',
  CARRIER_CANCELED: 'Transportadora Cancelou',
  CARRIER_ERROR: 'Erro da Transportadora',
  CARRIER_TIMEOUT: 'Transportadora Não Respondeu a Tempo',
  SELLER_CANCELED: 'Marca Cancelou',
  SCHEDULED_DELIVERY: 'Entrega Agendada',
  DELIVERED: 'Entregue',
  WITHDRAWN: 'Retirado',
  DRIVER_CONFIRMED: 'Entregador Confirmou',
  DRIVER_ASSIGNED: 'Entregador Atribuído',
  DRIVER_REJECTED: 'Entregador Recusou',
  COLLECTING: 'Coletando',
  DELIVERY_FAILED: 'Falha na Entrega',
  COLLECT_FAILED: 'Falha na Coleta',
  RETURN_FAILED: 'Falha na Devolução',
  WAITING_FOR_DRIVER: 'Aguardando Entregador',
  WAITING_TAKEOUT_CONFIRMATION: 'Aguardando Confirmação de Retirada',
  READY_FOR_TAKEOUT: 'Pronto para Retirada',
  OUT_OF_COVERAGE: 'Fora da Área de Cobertura'
};

const statusMap: { [key in OrderStatus]: string } = {
  SUCCESSFUL: 'Sucesso',
  CREATED: 'Criado',
  DISPATCHED: 'Despachado',
  START_DELIVERY: 'Em Rota',
  CANCELED: 'Cancelada',
  FAILED: 'Falha',
  TREATED: 'Tratada',
  VISIT_LATER: 'Visitar mais tarde',
  PENDING: 'Pendente',
  COLLECTED: 'Coletado',
  ORDER_FAILED: 'Falha na Solicitação',
  MANUAL_HANDLE: 'Baixa manual',
  HANDLING: 'Em manuseio',
  RETURNING: 'Em Devolução',
  RETURNED: 'Devolvido',
  IN_TRANSIT: 'Em trânsito'
};

export class StatusMapper {
  static mapSubStatus(subStatus: DeliverySubStatus) {
    return subStatusMap[subStatus];
  }

  static mapStatus(status: OrderStatus) {
    return statusMap[status];
  }
}
