import Logo from 'src/components/atoms/Logo/logo.component';
import SearchSection from 'src/components/organisms/SearchSection/search-section.component';

import { CenterWrapper } from './wrappers';

const Search = () => {
  return (
    <CenterWrapper>
      <Logo />
      <SearchSection />
    </CenterWrapper>
  );
};

export default Search;
