import { Container as Status } from 'src/components/molecules/OrderStatus/order-status.styles';
import useInvoiceUtils from 'src/hooks/useInvoiceUtils';
import useSellerTheme from 'src/hooks/useSellerTheme';
import styled from 'styled-components';

export const Container = styled.div.attrs((props) => {
  const { hasDriverLocation } = useInvoiceUtils();
  const { isLoading } = useSellerTheme();
  return {
    ...props,
    $hasDriverLocation: hasDriverLocation,
    $boxShadowColor: isLoading ? props.theme.colors.loading : props.theme.colors.main
  };
})`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.bg};
  margin: 10px 0px;

  max-width: 42rem;

  padding: 0 0 0.2rem 0;
  box-sizing: border-box;
  pointer-events: all;
  border-radius: ${(p) => p.theme.borderRadius};

  ${Status} {
    padding: 10px;
  }
`;
