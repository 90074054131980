import styled from 'styled-components';

import { BaseComponent } from '../base';

const Divider = styled.div<BaseComponent>`
  margin: ${(p) => p.m};
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colors.gray[500]};
`;

export default Divider;
