import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100vh;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
  }
  * {
    box-sizing: border-box;
  }
  #root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  html{
    font-size: 62.5%;
    background-color: ${(props) => props.theme.colors.bg};
  }

  div{
    /* padding: 2rem; */
    &#root{
      padding: 0;
    }
  }
  
  @media (max-width: 1080) {
    html {
      font-size: 58%;
    }
  }

  @media (max-width: 600px) {
    html{
      font-size: 50%;
    }
  }

  p {
    font-size: 1.4rem;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueUltraLight.otf') ;
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueThin.otf') ;
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueLight.otf') ;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueRoman.otf') ;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/helvetica-neue/HelveticaNeueMedium.otf') ;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueBold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueHeavy.otf') ;
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('./fonts/helvetica-neue/HelveticaNeueBlack.otf') ;
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "EuropaBold";
    src: url("./fonts/EuropaBold/EuropaBold.ttf");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #adadad;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #8f8f8f;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f;
  }

`;
