import StatusCircleSkeleton from 'src/components/atoms/StatusCircle/status-circle.skeleton';

import { Container } from './order-status.styles';

const OrderStatusSkeleton = () => {
  return (
    <Container>
      {[...new Array(3)].map((_, index) => (
        <StatusCircleSkeleton key={index} />
      ))}
    </Container>
  );
};

export default OrderStatusSkeleton;
