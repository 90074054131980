import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  max-height: 300px;
  overflow-x: auto;
  border-radius: ${(p) => p.theme.borderRadius};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 1rem;
    margin: 1rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    border-radius: 1rem;

    &:hover {
      background: #8f8f8f;
    }
  }
`;
