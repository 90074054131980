import TextSkeleton from 'src/components/atoms/Text/text.skeleton.component';

import { Container, Title } from './track-header.styles';

const TrackHeaderSkeleton: React.FC = () => {
  return (
    <Container>
      <Title>
        <TextSkeleton w="80%" m="2px 0" />
      </Title>
    </Container>
  );
};

export default TrackHeaderSkeleton;
