import useSellerThemeValue from 'src/hooks/useSellerTheme';
import { Logo, Container } from './tracking-logo.styles';

const TrackingLogo = () => {
  const { theme } = useSellerThemeValue();
  const logo = <Logo data-cy="tracking-logo" src={theme.logo} />;
  return (
    <Container $bg={theme.logo_bg}>
      {theme.logo_link ? (
        <a href={theme.logo_link} target="_blank" rel="noreferrer">
          {logo}
        </a>
      ) : (
        logo
      )}
    </Container>
  );
};

export default TrackingLogo;
