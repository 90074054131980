import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';

import { Container, Row, Title } from './expandable-item.styles';

const SkeletonExpandableItem: React.FC = () => {
  return (
    <Container>
      <Row>
        <Title>
          <Skeleton />
        </Title>
        <IconButton disabled>
          <ExpandMoreIcon fontSize="large" />
        </IconButton>
      </Row>
    </Container>
  );
};

export default SkeletonExpandableItem;
